import React, { useState, useEffect, useRef } from 'react'
import './styles.scss'
const Masonry = props => {
  const masonryRef = useRef(null)

  const [columns, setColumns] = useState(1)

  const getColumns = w => {
    let cols = 0
    props.brakePoints.some((item, index) => {
      if (w < item) {
        cols = index + 1
        return true
      }
    })

    return cols === 0 ? props.brakePoints.length + 1 : cols
  }
  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const onResize = () => {
    const cols = getColumns(masonryRef.current.offsetWidth)

    if (cols !== columns) {
      setColumns(cols)
    }
  }

  const mapChildren = () => {
    let col = []
    const numC = columns
    for (let i = 0; i < numC; i++) {
      col.push([])
    }
    return props.children.reduce((p, c, i) => {
      p[i % numC].push(c)
      return p
    }, col)
  }

  return (
    <div className="masonry" ref={masonryRef}>
      {mapChildren().map((col, ci) => {
        return (
          <div
            className="column"
            style={{ maxWidth: `${100 / columns}%` }}
            key={ci}
          >
            {col.map((child, i) => {
              return (
                <div className="item" key={i}>
                  {child}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
export default Masonry
