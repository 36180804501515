import fetchPonyfill from 'fetch-ponyfill'
const { fetch } = fetchPonyfill(Promise)
let GLOBAL = require('./Global')

const baseURL = () => {
  if (GLOBAL.ENVIRONMENT === 'development') {
    return GLOBAL.DEVELOPMENT_URL
  } else if (GLOBAL.ENVIRONMENT === 'staging') {
    return GLOBAL.STAGING_URL
  } else {
    return GLOBAL.PRODUCTION_URL
  }
}

const fetchData = async (url = '', { method, headers, body }) => {
  const base = baseURL()
  const URL = `${base}/${url}`

  const response = await fetch(URL, {
    method: method,
    headers: headers,
    body: body
  })
  return response.json()
}

export default fetchData
